<template>
    <div class="container">
        <div v-if="!isLoading">
            <!-- <div class="head-dom">
                <p class="title">{{ $route.query.title }}</p>
            </div> -->
            <div class="articleDetailLayout" v-if="allArticleData.length">
                        <div class="artList"
                            :data="allArticleData"
                            v-for="(item,index) in allArticleData"
                            :key="index"
                             @click="detailClick(item)"
                        >
                            <template v-if="item">
                                <div class="artImg" v-if="item.articleCover">
                                    <img :src="item.articleCover" :alt="item.articleTitle"/>
                                </div>
                                <div
                                  class="artContent"
                                  :class="item.articleCover ? 'cover': 'uncover'"
                                >
                                    <p class="artTitle">{{ item.articleTitle }}</p>
                                    <p v-if="item.articleAbstract" class="artAbstract">
                                      {{item.articleAbstract}}
                                    </p>
                                    <p class="artDetail">
                                        <span>{{ item.publishTime | formatTime }}</span>
                                        <img
                                          class="seeNum"
                                          src="https://hrss-tes.oss-cn-beijing.aliyuncs.com/hrss/image/2021/04/1619767042239.png" alt="">
                                        <!-- <span class="seeNum"></span> -->
                                        <span>{{ item.readNum || 0 }}</span>
                                    </p>
                                </div>
                            </template>
                        </div>
                        <!-- <dyPaging :paging="paging" /> -->
            </div>
            <div slot="empty" v-if="!allArticleData.length">
                <div class="empty-tip">
                    <img src="../asset/images/tp-zwsj@2x.png" alt="">
                    <span>暂无数据</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    data () {
        return {
            isLoading: true,
            articleId: '',
            allArticleData: [],
            paging: {                //翻页
                params: {
                    pageNum: 1,
                    pageSize: 10
                },
                total: null
            },
        }
    },
    filters: {
        formatTime(val) {
            if (val) {
                return val.split(' ')[0].split('-').join('/')+' '+val.split(' ')[1].split(':').slice(0,2).join(':')
            } else {
                return "待定"
            }
        },

    },
    created () {
        this.getArticleByClass();
    },
    // activated () {
    //     this.getArticleByClass();
    // },
    methods: {
        detailClick(item){
          if(item.enclosureType==1){
            let ele=document.createElement('a')//创建a标签
            // ele.download=this.downloadForm.downloadFile//下载名称
            ele.target="_blank"//新标签
            ele.href=item.enclosureUrl//地址
            document.body.append(ele)//将a标签插入页面
            ele.click()//点击a标签实现跳转
            ele.remove()//移除a标签
            return;
          }
            this.$router.push({
                path: '/cms/detail',
                query: {
                    articleId: item.id,
                 }
            });
        },
        getData () {
            this.getArticleByClass();
        },
        getArticleByClass() {
            const datas = {
                specialId: this.$route.query.specialId,
                classId: this.$route.query.classId,
                pageNum: this.paging.params.pageNum,
                pageSize: this.paging.params.pageSize,
            }
            this.$api.news.getArticleByClass(datas).then((res) => {
                console.log(res.data)
                this.isLoading = false;
                if (res.success) {
                    this.allArticleData = res.data.list;
                    this.paging.total = res.data.total;
                    console.log(this.$forceUpdate());
                } else {
                    this.$message.error(res.message);
                }
            });
        },
    },

}

</script>

<style lang="stylus" scoped>
.head-dom
    width: 100%;
    height: 56px;
    background-color #1972FF;
    .title
        margin: 0 auto;
        width: 144px;
        height: 56px;
        background: #1666E5;
        line-height 56px;
        text-align center;
        color #ffffff;
        font-size 20px;

.textCut
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
.articleDetailLayout
    width:750px;
    background:#fff;
    margin:12px auto;
    min-height: 800px
.articleTop
    padding:24px;
    border-bottom:1px solid  #EDF1F8;
    overflow: hidden;
.aritcleTopLeft
    & img
        width:80px;
        height:60px;
        vertical-align: middle;
.aritcleTopRight
    margin-left:16px;
.aritcleTopRightTitle
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
.aritcleTopRightTitleDetail
    margin-top:8px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    & span
        display:inline-block;
        vertical-align: middle;
.articleCont
    padding:24px;
    /deep/& img
        max-width: 1080px;
        height: auto;
.articleBottom
    padding:22px 24px;
    border-top:1px solid #EDF1F8;
    overflow: hidden;

.fujianItem
    overflow: hidden;
    width:548px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
.fujianTitle
    width:88px;
.yasuobaoTitle
    padding-left:20px;
    background:url('../asset/images/yasuobao_icon.png') no-repeat left center;
    background-size:14px 14px;
.yasuobaoTitle>span
    margin-left:16px;
    color:#999;

.fujianDownload, .lijiqianwang
    color:#1972FF;

.renliziyuankiaoshiLink
    background:url('../asset/images/friendLink_icon.png') no-repeat left center;
    background-size:14px 14px;

.friendLinkItem
    margin-top:16px;

.artH5
    position: relative;
    padding: 95px 35px 95px 35px;
    width: 305px;
    height: 530px;
    background-image: url('https://hrss-tes.oss-cn-beijing.aliyuncs.com/hrss/image/2021/03/1615288507533.png');
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 10px;
    .scrY
        width: 305px;
        height: 546px;
        overflow-y: scroll;
    .scrY::-webkit-scrollbar {
        width: 3px;
    }
    .headT
        padding: 16px 0;
        margin: 0 16px;
        border-bottom:1px solid  #EDF1F8;
        overflow: hidden;
        .artT
            font-size: 20px;
            color: #333333;
            line-height: 28px;
            word-wrap:break-word;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        .artDetail
            margin-top: 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            & span
                display:inline-block;
                vertical-align: middle;
    .foot
        padding: 16px 0;
        margin: 0 16px;
        border-top: 1px solid  #EDF1F8;
        .Item
            overflow: hidden;
            width: 270px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .mt
                margin-top: 8px;
                margin-left: 0;
                line-height: 14px;
                .encN
                    width: 168px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    padding-top: 5px;


.container
    width: 750px;
    margin: 0 auto;

.articleDetailLayout
    padding 0 15px;
    box-sizing: border-box;
.artList
    width 100%;
    padding 15px 0;
    border-bottom 1px solid #EDEDED;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    box-sizing: border-box;
    .artImg
        & img
            width 200px;
            height 158px;
    .cover {
      width 415px;
      .artTitle {
        width 415px;
        overflow: hidden;//隐藏文字
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .artAbstract {
        width 415px;
      }
    }
    .uncover {
      .artTitle {
        width 690px;
      }
      .artAbstract {
        width 690px;
      }
    }
    .artContent
        margin-left:16px;
        .artTitle
            display: block;
            font-size: 16px;
            color: #333333;
            padding-top: 8px;
        .artAbstract
            padding: 15px 0 0 0;
            height: 76px;
            font-size: 13px;
            color: #666666;
            box-sizing: border-box;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            word-break: break-all;
        .artDetail
            margin-top: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            & span
              display:inline-block;
              vertical-align: middle;
            .seeNum {
              width: 24px;
              margin: 0 10px 0 24px;
            }
.empty-tip
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #D9DEE7;
    margin-top: 200px;
    padding-bottom: 30px;
    & img
        width: 100px;
</style>
