<template>
    <div :class="{ 'debug-info': isDebug }" class="cms-block">
        <div v-if="isDebug">--------------- START  ----------------</div>
        <div v-if="isDebug">
            [DEBUG INFO]:CMS BLOCK [{{ name }}] code: "{{ code }}"
        </div>
        <!-- <div v-if="code === 'F'">
            NOT SUPPORTED YET
        </div> -->
        <temp-render
            :ref="'temp-' + code"
            :code="code"
            :attr="obj"
            @action="onAction"
            @checkMore="onCheckMore"
            v-if="refresh && !isLoading"
        />
        <div v-if="isDebug">----------------- END --------------</div>
    </div>
</template>

<script>
import Vue from 'vue';
import tempRender from '@wd/temp-mobile';
Vue.use(tempRender)


export default {
    components: {
    },
    inject: ["isDebug"],
    props: {
        name: {
            type: String,
        },
        code: {
            type: String,
        },
        data: {
            type: Object,
        }
    },
    data() {
        return {
            refresh: true,
            isLoading: true,
            obj: {
                // 0:pc h5 全部展示 1：pc 展示 2：h5 展示
                terminal: 2,
                tempData: this.data,
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        onAction(data) {
            console.log(data);
            // { name: 'chekcMore', data: { id: 1212, } }
            switch(data.name) {
                case 'checkMore':
                    this.$router.push({
                        name: 'cms_list',
                        query: data.data,
                    })
                    break;
                case 'checkDetail':
                    this.$router.push({
                        name: 'cms_detail',
                        query: data.data,
                    })
                    break;
                case 'openVideo':
                    this.$router.push({
                        name: 'cms_video',
                        query: data.data,
                    })
                    break;
                case 'change':
                    this.getSpecialShow(data.data)
                    break;
                case 'openLocalPath':
                    this.openLocalPage(data.data)
                    break;

            }
        },
        async getSpecialShow(data) {
            data.pageSize =1000;
            this.refresh = false;
            let res = await this.$api.news.getSpecialShow({data: data});
            if (res.success && this.code == 'F') {
                this.obj.tempData = res.data;
                this.refresh = true;
            }
        },
        onCheckMore(data) {
            console.log(data);
        },
        loadData() {
            let specialId;
            try {
                specialId = this.data.setting.find(item => item.content !== undefined).content.specialId;
            } catch (e) {
                console.log(e)
            }   
            this.$api.cms.getCmsContent({
                specialId,
            }).then(res => {
                this.isLoading = false;
                this.obj.tempData = res;
            });
        },
        // 跳转本地路径
        openLocalPage(data) {
          if (data.localPath) {
            this.$router.push({
              path: data.localPath
            })
          }
        }
    }
}
</script>

<style lang="less">
.debug-info {
    border: 1px dashed pink;
}
.cms-block {
    max-width: 1200px;
    margin: 0 auto;
    // min-height: 78vh;
}
</style>
