<template>
  <div class="lego-block">
    <template v-if="ignoreBlock">
    </template>
    <template v-else>
      <component ref="comp" :is="componentId" v-if="!isCMS && componentId" 
          :data="tmplData" :tmplId="tmplId" @compAction="onCompAction"></component>
      <CmsBlock v-if="isCMS" :code="settings.label" :name="name" :data="settings"></CmsBlock>
    </template>
  </div>
</template>

<script>
import CmsBlock from './CmsBlock';


export default {
  name: 'dynamic-block',
  components: {
    CmsBlock,
  },
  props: {
    name: {
      type: String,
    },
    blockInfo: {
      type: Object,
      default() {
        return {};
      }
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    console.log(this.name, this.blockInfo,this.data);
    const settings = this.$store.getters.storeGetBlockSettings(this.blockInfo);
    const isCMS = settings && settings.module === 'cms';
    let tmplId = this.name;
    let data = this.data;
    let ignoreBlock;
    const theme = sessionStorage.getItem('theme');
    
    console.log(isCMS, settings);

    if (!isCMS) {
      data = settings;
     if (this.blockInfo.label === 'side') { // cms配置内容
        tmplId = 'Side';
      } 
    }
    // if not is adding component, use manually setted data
    // otherwise using props.data, hency you should watch this.props.data for updaing tmplData
    // otherwise will broken data reacting...
    // force update will update this
    // const data = this.data.IS_COMP_ADDING ? this.data : (this.tmplData || null);
    return {
      ignoreBlock,
      isCMS,
      settings,
      tmplData: data,
      componentId: null,
      tmplId,
    };
  },
  computed: {
    loader() {
      return () => import(`../../components/${this.tmplId}`);
    },
  },
  watch: {
    'name': {
      handler() {
        this.render();
      },
    },
    data(val) {
      this.tmplData = val;
    }
  },
  methods: {
    render() {
      // test this module getter can get specifiied module
      this.loader()
        .then(() => {
          // if this.loader can be called,
          // assign this module getter to component
          this.componentId = () => this.loader();
        })
        .catch(() => {
          // pre setted loader  () => import(`../components/${this.data.name}`); not working
          // failback to module not found block
          if (this.$route.query.is_debug === '1973') {
            this.componentId = () => import('../../components/NotFound.vue');
          } else {
            this.componentId = '';
          }
        });
    },
    setData(data) {
        this.tmplData = data;
        // const fn = this.$refs.comp.setData;
        // fn && fn(data);
    },
    onCompAction(data) {
        this.$emit('compAction', data);
    },
  },
  created() {
      
    this.render();
  },
};
</script>

<style lang="less">
</style>
