<template>
    <div class="dynamic-page">        
        <comp-render :data="pageConf.components" 
            v-if="!isLoading" :render-only="true"></comp-render>
    </div>
</template>

<script>
import CompRender from './dynamicComp/CompRender';
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        CompRender,
    },
    provide() {
        return {
            isDebug: this.$route.query.is_debug === '1973',
        }
    },
    data() {
        const pageName = this.$route.name;
        console.log(this.$route);
        const pageConf = this.$store.getters.storeGetPageConfByName(pageName);
        console.log('======', pageConf);
        this.storeSetPageSettings(pageConf.blockContent.blockList);

        
        return {
            isLoading: false,
            code: 'A',
            pageConf: pageConf,
        }
    },
    computed: {
        ...mapGetters(['storeGetPageConfByName']),
    },
    created() {
        console.log(this.pageConf);
    },
    methods: {
        ...mapMutations(['storeSetPageSettings']),
    },
}
</script>

<style lang="less">
.dynamic-page {
    background: #fff;
}


.comp-empty {
    outline: 1px dashed #555 !important;
    outline-offset: -1px !important;
    box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 10%), inset 0 0 0 4px rgba(255, 255, 255, 20%), inset 0 0 0 1px rgba(255, 255, 255, 30%);
    padding-bottom: 75px;
    padding-right: 75px;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}  
</style>