<template>
  <div class="articleDetailLayout">
    <div class="articleTop">
      <div class="aritcleTopLeft fl">
        <img
          :src="allArticleData.articleCover"
          :alt="allArticleData.articleTitle"
          v-if="allArticleData.articleCover"
        />
      </div>

      <div class="aritcleTopRight fl">
        <p class="aritcleTopRightTitle">
          {{ allArticleData.articleTitle }}
        </p>
<!--          <p v-if="allArticleData&&allArticleData.articleTitle&&allArticleData.articleTitle.length>9" class="aritcleTopRightTitle">-->
<!--              {{ String(allArticleData.articleTitle).slice(0,9)+'...' }}-->
<!--          </p>-->

          <div class="aritcleTopRightTitleDetail">
            <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData.sortType==1">发表时间：{{allArticleData.publishTime|formatTime}}</span>

            <span v-if="(showPublishFlag==0||showPublishFlag=='')&&allArticleData.sortType==2">发表时间：{{allArticleData.customTime|formatTime}}</span>

            <span v-if="showReadFlag==0||showReadFlag==''" class="seeNum">
            {{ allArticleData.readNum || 0 }}
          </span>
        </div>
      </div>
    </div>

    <div class="articleCont" v-html="allArticleData.content"></div>

     <!-- 视频文章 -->
    <div
      class="article-video-h5"
      v-if="allArticleData.articleVideoUrl"
    >
      <video
        class="article-video-play"
        controls
        autoplay
        :src="allArticleData.articleVideoUrl">
      </video>
    </div>
<!--    <div class="articleBottom">-->
<!--      <div class="articleBottomLeft">-->
<!--        <div class="fujianItem">-->
<!--          <div class="fujianItemLeft">-->
<!--            <p class="fujianTitle fl">附件：</p>-->
<!--            <p class="yasuobaoTitle fl">-->
<!--              <span>{{ allArticleData.enclosureName }}.zip</span>-->
<!--              <span>{{ allArticleData.enclosureSize }}MB</span>-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="fujianItemRight fr">-->
<!--            <a-->
<!--              :href="allArticleData.enclosureUrl"-->
<!--              class="fujianDownload"-->
<!--              target="_blank"-->
<!--              >下载</a-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="fujianItem friendLinkItem">-->
<!--          <div class="fujianItemLeft">-->
<!--            <p class="fujianTitle fl">相关链接：</p>-->
<!--            <p class="yasuobaoTitle renliziyuankiaoshiLink fl">-->
<!--              {{ allArticleData.externalName }}-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="fujianItemRight fr">-->
<!--            <a-->
<!--              :href="allArticleData.externalUrl"-->
<!--              class="lijiqianwang"-->
<!--              target="_blank"-->
<!--              >立即前往</a-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  name: "articleDetial",

  data() {
    return {
      articleId: "",
      allArticleData: null,
      showReadFlag:this.$route.query.showReadFlag?this.$route.query.showReadFlag:'',
      showPublishFlag:this.$route.query.showPublishFlag?this.$route.query.showPublishFlag:''
    };
  },
  filters: {
    formatTime(val) {
      return (
        val.split(" ")[0].split("-").join("/") +
        " " +
        val.split(" ")[1].split(":").slice(0, 2).join(":")
      );
    },
  },
  created() {
    this.getArctileDetail();
  },
  computed: {},
  mounted() {},

  beforeDestroy() {},

  methods: {
    getArctileDetail() {
      this.$api.news
        .getArticleDetail({ id: this.$route.query.articleId })
        .then(async (res) => {
          if (res.success) {
            this.allArticleData = res.data;
            // this.allArticleData.articleTitle ='有福之州，七溜八溜不离福州。”24日下午，福建福州福山郊野公园福榕园，正在福建考察调研的习近平总书记来到这里'

          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style scoped>
@import "../asset/articleDetail.css";
</style>
